import React from 'react'
import { Link } from 'gatsby'

import { PageLayout, PageBreadcrumb } from '../../components'
import { t } from '../../lib/locale'

const ContentAppointmentTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  const renderNL = (
    <>
      <div className="row">
        <div className="col-12">
          <iframe src="https://app.acuityscheduling.com/schedule.php?owner=19585606" title="Afspraak maken" width="100%" height="800" frameBorder="0"></iframe>
          <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
        </div>
      </div>
    </>
  )

  const renderEN = (
    <>
      <div className="row">
        <div className="col-12">
        <iframe src="https://app.acuityscheduling.com/schedule.php?owner=19585606" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe>
        <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
        </div>
      </div>
    </>
  )

  return (
    <PageLayout className="shopping-bag" lang={lang} switcher={page}>
      <PageBreadcrumb current={page} items={page.breadcrumbs} lang={lang} />
      <h1 className="mb-4">{t(page, 'title', lang)}</h1>
      {+lang.id_lang === 1 && renderEN}
      {+lang.id_lang === 4 && renderNL}
    </PageLayout>
  )
}

export default ContentAppointmentTemplate
